<template>
    <div class="menu">
        <section class="bgimage d-flex justify-content-center align-items-center text-white text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <h1>Menukaart</h1>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="d-flex justify-content-center align-items-center text-center bg-white my-5">
            <div class="container-lg">
                <div class="d-inline">
                    <b-button v-on:click="menu = true" :variant="variantAyce" pill class="mx-2 my-2">
                        All You Can Eat
                    </b-button>
<!--                    <b-button v-on:click="menu = false" :variant="variantAfhaal" pill class="mx-2 my-2">-->
<!--                        À la carte / Afhalen-->
<!--                    </b-button>-->
                </div>
                <b-row class="mt-5" v-if="menu === true">
                    <b-col sm="12" lg="12">
                        <div class="section-title">
                            <h2>All you can eat Menu</h2>
                            <h4>Onderstaand alleen te verkrijgen bij een All You Can Eat arrangement</h4>
                            <p>*Let op: voor enkele exclusieve gerechten geldt een kleine toeslag. Informeer in het restaurant voor meer details.</p>
                        </div>
                    </b-col>
                    <b-col cols="12" lg="12">
                        <b-img src="@/assets/menu/ayc/sushi-king-menu-2024-01.png" fluid alt="Responsive image"></b-img>
                    </b-col>
                    <b-col class="mt-4" cols="12" lg="12">
                        <b-img src="@/assets/menu/ayc/sushi-king-menu-2024-02.png" fluid alt="Responsive image"></b-img>
                    </b-col>
                </b-row>
                <b-row class="mt-5" v-else>
                    <b-col lg="12">
                        <h2>Afhaal Japans</h2>
                    </b-col>
                    <b-col lg="12">
                        <b-img src="@/assets/menu/afhaal/afhaal01-2022.png" fluid alt="Responsive image"></b-img>
                    </b-col>
                    <b-col class="mt-4" lg="12">
                        <b-img src="@/assets/menu/afhaal/afhaal02-2022.png" fluid alt="Responsive image"></b-img>
                    </b-col>
<!--                    <b-col class="mt-5" lg="12">-->
<!--                        <h2>Afhaal Chinees</h2>-->
<!--                    </b-col>-->
<!--                    <b-col lg="12">-->
<!--                        <b-img src="@/assets/menu/afhaal/afhaal-chinees.png" fluid alt="Responsive image"></b-img>-->
<!--                    </b-col>-->
                </b-row>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'Menu',
        data() {
            return {
                menu: true,
            }
        },
        computed: {
            variantAyce() {
                return this.menu ? 'danger' : 'outline-danger';
            },
            variantAfhaal() {
                return this.menu ? 'outline-danger' : 'danger';
            },
        },
    }
</script>

<style scoped>
    .menu .bgimage {
        background: url('../assets/header/banner-menu.jpg');
        background-size: cover;
    }
</style>
